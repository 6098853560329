/* eslint-disable react-hooks/exhaustive-deps */
import { RoleFunction } from '@eagle/common';
import { AlertResponse, LastThingEvent, Person, PersonType, Thing, ThingType } from '@eagle/core-data-types';
import {
  Alert,
  ALERTS_DAYS_LIMIT,
  AlertsTable,
  Breadcrumbs,
  CacheDataTypes,
  DetailPage,
  DynamicIcon,
  ErrorMessage,
  evaluate,
  FeatureIcons,
  FetchOne,
  FetchOneOfAll,
  FindItemsDeferredPaginatedResponse,
  FlexBox,
  getLastThingLocation,
  getListResultDescription,
  GroupsCard,
  LastThingStateProvider,
  MiddleSpinner,
  Pagination,
  PersonCard,
  PersonThingCard,
  PortalFeatureIcons,
  Portals,
  PromiseState,
  Query,
  RECENT_EVENT_DATA_MAX_DAYS_FLAG,
  T_MANY,
  T_ONE,
  ThingInlayMap,
  TRACK_PERSON_DETAIL_DEFAULT_TEMPLATE,
  Undefinable,
  useAuthenticated,
  useBoolFlag,
  useCustomRoutes,
  useDynamicModule,
  useFetchOneCache,
  useFlags,
  useHasAuthorization,
  useNumberFlag,
  usePromise,
  useUiTemplate,
  validateLocationType
} from '@eagle/react-common';
import { Card, CardContent, Link, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import Axios from 'axios';
import { DateTime } from 'luxon';
import { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHref, useNavigate, useParams } from 'react-router-dom';

interface PersonMapProps {
  error?: Error;
  event?: LastThingEvent;
  status: PromiseState;
}

interface Props {
  person?: Person;
}

const MAP_PERMISSIONS = [RoleFunction.DATA_VIEWER] as const;
const ALERT_PERMISSIONS = [RoleFunction.ALERT_VIEWER] as const;

export const personDetailItemRenderer = (entity: Person, personType: PersonType, portal: Portals, groupIds: string[], noGroupsText: string, isClickable: boolean, mapPermissions: boolean, personMap: JSX.Element, personAlerts: JSX.Element, template: unknown): JSX.Element => {
  return <>{evaluate(template, { entity, personType, portal, groupIds, noGroupsText, isClickable, mapPermissions, personMap, personAlerts })}</>;
};

const PersonMap: FC<PersonMapProps> = ({ error, event, status }) => {
  const thingLastLocation = validateLocationType(() => event?.data.location);

  if (status === 'pending') return <MiddleSpinner />;
  if (error) return <ErrorMessage error={error} />;
  if (!event || !thingLastLocation) return <></>;

  const thingId = event.thingId;

  return (
    <FetchOne
      id={thingId}
      dataType={CacheDataTypes.THING}
      renderFactory={(thing: Thing) => (
        <FetchOneOfAll
          id={thing.thingTypeId}
          dataType={CacheDataTypes.THING_TYPE}
          renderFactory={(thingType: ThingType) => {
            const trackingFeature = thingType.features.find((feature) => feature.featureId === 'tracking');
            if (!trackingFeature) return <></>;
            return <LastThingStateProvider>
              <FlexBox sx={{ flexGrow: 0, height: 400 }}>{<ThingInlayMap thing={thing} />}</FlexBox>
            </LastThingStateProvider>;
          }}
        />
      )}
    />
  );
};

const PersonAlerts: FC<{ person: Person }> = ({ person }): JSX.Element => {
  const { restClient } = useAuthenticated();
  const { t } = useTranslation(['track', 'manage']);
  const [isRecentAlertsView, setIsRecentAlertsView] = useState(true);
  const maxDays = useNumberFlag(RECENT_EVENT_DATA_MAX_DAYS_FLAG) || ALERTS_DAYS_LIMIT;

  const handleQueryChanged = useCallback((_: Query, pagination: Pagination, isDaysLimit: boolean): FindItemsDeferredPaginatedResponse<AlertResponse> => {
    const cancelToken = Axios.CancelToken.source();
    // TODO TP-5859 update axios, use AbortController instead of deprecated CancelToken
    const startDate = DateTime.now().minus(isDaysLimit ? { days: maxDays } : { years: 3 });
    return {
      cancel: () => cancelToken.cancel(),
      promise: restClient.alert.getAlertsByPersonId(person._id, {
        dateRangeStart: startDate.toUTC().toJSDate(),
        limit: pagination.limit,
        skip: pagination.skip,
        sort: JSON.stringify({ occurred: -1 }),
      }, { cancelToken: cancelToken.token }),
    };
  }, [restClient]);

  if (person.deleted) {
    return (
      <Card>
        <CardContent>
          <Stack spacing={2}>
            <Typography variant="h5">{t('common:terms.alert', { count: T_MANY })}</Typography>
            <Alert severity='info'>{t('common:page.details.alert-person-display.hint')}</Alert>
          </Stack>
        </CardContent>
      </Card >
    );
  }

  return (
    <Card sx={{ '& .MuiPaper-root': { boxShadow: 'none' } }}>
      <AlertsTable
        onQueryChanged={handleQueryChanged}
        renderTitle={(alerts, matchCount) => (
          <CardContent>
            {
              isRecentAlertsView ?
                <Typography variant="h5" color="text.primary" sx={{ mb: 2 }}>{t('common:component.alert-table.labels.recent-alert')}
                  <Typography component="span" variant="body2" color="text.secondary" fontStyle="italic" sx={{ display: 'block' }}>
                    {!alerts.length ?
                      t('common:component.media-data.hint.displaying-last', { count: maxDays }) :
                      t('common:component.alert-table.hint.results', { count: matchCount, dayCount: maxDays, entity: t('common:terms.alert', { count: matchCount }) })}
                  </Typography>
                </Typography>
                :
                <Typography variant="h5" color="text.primary" sx={{ mb: 2 }}> {t('common:terms.alert', { count: T_MANY })}
                  <Typography component="span" variant="body2" color="text.secondary" fontStyle="italic" sx={{ display: 'block' }}>
                    {getListResultDescription({ count: matchCount, entityKey: 'common:terms.alert', t })}
                  </Typography>
                </Typography>
            }
          </CardContent>
        )}
        showPerson={false}
        smallTable={true}
        showGroups={false}
        showFeature={false}
        updateIsRecentAlertsView={setIsRecentAlertsView}
        isRecentAlertsView={isRecentAlertsView}
      />
    </Card>
  );
};

export const PersonDetail: FC<Props> = ({ person = undefined }) => {
  const { restClient, userInfo } = useAuthenticated();
  const { people: customRoutesPeople, history: customRoutesHistory } = useCustomRoutes();
  const params = useParams();
  const personId = person?._id || params.personId;
  const navigate = useNavigate();
  const { t } = useTranslation(['common', 'track', 'terms']);
  const href = useHref(`/${customRoutesPeople}`);
  const flagThingHistory = useBoolFlag('track-thing-history-feature');
  const personCache = useFetchOneCache(CacheDataTypes.PERSON);
  const { hasAuthorization } = useHasAuthorization();
  const { module, loaded: moduleLoaded } = useDynamicModule<FeatureIcons>('feature-icons', PortalFeatureIcons.Tracking);
  const { template: template, loaded: templateLoaded } = useUiTemplate('track-person-detail', TRACK_PERSON_DETAIL_DEFAULT_TEMPLATE);
  const flags = useFlags();

  const mapPermissions = hasAuthorization(MAP_PERMISSIONS);
  const alertPermissions = hasAuthorization(ALERT_PERMISSIONS);

  // TP XXXX - replace with call to person-last-location endpoint
  const [lastThingEvent, lastThingEventError, lastThingEventStatus] = usePromise<Undefinable<LastThingEvent>>(
    async () => {
      if (!personId) throw Error(t('common:common.hint.last-contact-no-id', { entity: t('terms:person', { count: T_ONE }) }));
      const thingId = await restClient.personThing.getCurrentRangesByPerson(personId)
        .then((response) => response[0]?.thingId);
      if (!thingId) return undefined;
      return getLastThingLocation(restClient, thingId);
    },
    [restClient]
  );

  const renderPersonMap = (): JSX.Element => {
    return (
      <>
        {mapPermissions
          && <PersonMap
            error={lastThingEventError}
            event={lastThingEvent}
            status={lastThingEventStatus}
          />
        }
      </>
    );
  };

  const renderRecentAlerts = (data: Person): JSX.Element => {
    return (
      <>
        {alertPermissions && <PersonAlerts person={data} />}
      </>);
  };

  const renderPageContent = (data: Person): JSX.Element => {
    const personMap = renderPersonMap();
    const personAlerts = renderRecentAlerts(data);

    return (
      <FetchOneOfAll
        id={data.personTypeId}
        dataType={CacheDataTypes.PERSON_TYPE}
        renderFactory={(personType: PersonType) => {
          if (flags['portals-data-templated-person-detail-feature-temporary']) {
            return <>
              {personDetailItemRenderer(data, personType, Portals.TRACK, data.groupIds, t('track:page.person-detail.hint.no-person'), false, mapPermissions, personMap, personAlerts, template)}
            </>;
          }
          return <>
            <Stack
              direction="column"
              flex={[2, 2]}
              spacing={2}
              sx={{ minWidth: 0 }}
            >
              <PersonCard data-testid="person-card" entity={data} entityType={personType} portal={Portals.TRACK} />
              <GroupsCard groupIds={data.groupIds} noGroupsText={t('track:page.person-detail.hint.no-person')} isClickable />
              <PersonThingCard person={data} portal={Portals.TRACK} />
            </Stack>

            <Stack
              direction="column"
              flex={[3, 3]}
              spacing={2}
            >
              {renderPersonMap()}
              {renderRecentAlerts(data)}
            </Stack>
          </>;
        }}
      />
    );
  };

  const viewHistory = (): void => {
    const date = lastThingEvent?.occurred ? DateTime.fromJSDate(lastThingEvent.occurred) : DateTime.now();

    navigate(`/${customRoutesHistory}`, {
      state: {
        dateEnd: date.plus({ days: 1 }).startOf('day').toJSDate(),
        dateStart: date.startOf('day').toJSDate(),
        entityId: personId,
        entityItemFields: {
          entity: CacheDataTypes.PERSON,
          entityType: CacheDataTypes.PERSON_TYPE,
          entityTypeKey: 'personTypeId',
        },
        filters: [],
        focused: true,
      },
    });
  };

  const actions = flagThingHistory
    ? [
      {
        label: t('track:common.action.view-history'),
        onClick: viewHistory,
        icon: <DynamicIcon icon={module?.HistoryIcon ? <module.HistoryIcon /> : null} />,
        testId: 'view-history-button',
      },
    ]
    : [];

  const breadcrumbs = (
    <Breadcrumbs>
      <Link
        color="inherit"
        href={href}
        underline="hover"
      >
        {t('terms:person', { count: T_MANY })}
      </Link>
      <Typography color="text.primary" data-testid="person-detail-subtitle-breadcrumb">{t('common:page.person-details.title')}</Typography>
    </Breadcrumbs>
  );

  const loadData = useCallback(
    () => {
      if (!person) return personCache.one<Person>(personId);
      return Promise.resolve(person);
    },
    [personId, userInfo.accountId]
  );

  if (flags['portals-data-templated-person-detail-feature-temporary']) {
    if (!moduleLoaded || !templateLoaded) return <MiddleSpinner />;
  } else {
    if (!moduleLoaded) return <MiddleSpinner />;
  }

  return (
    <Box sx={{
      display: 'flex',
      flex: [1, 1],
      flexDirection: 'column',
      minWidth: 0,
    }}>
      <DetailPage<Person>
        data-testid='person-detail'
        actions={actions}
        breadcrumbs={breadcrumbs}
        loadData={loadData}
        pageIcon={module?.PersonIcon && <module.PersonIcon />}
        renderDisplay={({ display }) => display}
        renderPageContent={renderPageContent}
        renderPageTitle={(data: Person) => data.display}
      />
    </Box>
  );
};

export default PersonDetail;
